import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CartItem from '../components/CartItem';
import { Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo'; 
import Modal from "../components/home/modal"

const Cart = ({ pageContext }) => {
  const isBrowser = typeof window !== 'undefined';
  const userIdFromContext = pageContext.userId;
  const userIdFromLocalStorage = isBrowser 
  ? JSON.parse(localStorage.getItem('user'))?.id 
  : null;
  const userId = userIdFromContext || userIdFromLocalStorage;

  const [cart, setCart] = useState({ items: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCart = async () => {
      if (!userId) {
        setError('User ID not found');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${process.env.GATSBY_API_URL}/cart/${userId}`);
        setCart(response.data);

        // Update local storage with the latest cart data
        localStorage.setItem('cartItems', JSON.stringify(response.data.items));
        localStorage.setItem('cartCount', response.data.items.length);
        window.dispatchEvent(new Event('storage'));
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCart(); // Fetch the latest cart data on page load
  }, [userId]);

  useEffect(() => {
    if (cart.items.length > 0) {
      localStorage.setItem('cartCount', cart.items.length);
      localStorage.setItem('cartItems', JSON.stringify(cart.items));
      window.dispatchEvent(new Event('storage'));
    }
  }, [cart]);

  const handleUpdateQuantity = async (drugId, quantity) => {
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const itemToUpdate = cartItems.find(item => item.drug._id === drugId);

    if (itemToUpdate) {
      try {
        const response = await axios.put(`${process.env.GATSBY_API_URL}/cart/update`, {
          userId: localStorage.getItem('userId'),
          drugId,
          quantity,
          selectedSeller: itemToUpdate.selectedSeller,
          sellerType: itemToUpdate.sellerType,
          price: itemToUpdate.price
        });

        localStorage.setItem('cartItems', JSON.stringify(response.data.items));
        localStorage.setItem('cartCount', response.data.items.length);
        setCart(response.data);
        window.dispatchEvent(new Event('storage'));
      } catch (error) {
        console.error('Error updating cart item:', error);
      }
    } else {
      console.error('Item to update not found in local storage');
    }
  };

  const handleRemoveItem = async (drugId) => {
    try {
      const response = await axios.delete(`${process.env.GATSBY_API_URL}/cart/remove`, { data: { userId: localStorage.getItem('userId'), drugId } });
      localStorage.setItem('cartItems', JSON.stringify(response.data.items));
      localStorage.setItem('cartCount', response.data.items.length);
      setCart(response.data);
      window.dispatchEvent(new Event('storage'));
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  const handleClearCart = async () => {
    try {
      await axios.delete(`${process.env.GATSBY_API_URL}/cart/clear`, { data: { userId } });

      localStorage.removeItem('cartItems');
      localStorage.setItem('cartCount', 0);
      setCart({ items: [] });
      window.dispatchEvent(new Event('storage'));
    } catch (error) {
      console.error('Error clearing cart:', error);
    }
  };

  if (loading) {
    return <div>Loading cart...</div>;
  }

  if (error) {
    return <div>Error fetching cart: {error}</div>;
  }

  return (
    <Layout>
      <Modal />
      <SEO
        title="Shopping Cart - Review Your Items"
        description="View and manage the items in your shopping cart before proceeding to checkout. Make updates and ensure everything is ready for a seamless purchase experience."
        keywords="shopping cart, review items, manage cart, checkout process, online shopping"
      />
      <div className="cart">
      <h5>Your Cart</h5>
        <div className='flex items-center justify-center'>
          <Link to="/checkout">
            <Button 
              style={{ 
                backgroundColor: '#03989e', 
                marginLeft: '20px', 
                marginRight: '5px',
                borderColor: '#03989e', 
                padding: '5px 10px', 
                width: '200px', 
                fontSize: '14px' 
              }} 
              className="btn btn-primary"
            >
              Proceed to Checkout
            </Button>
          </Link>
          <Button 
            style={{ 
              backgroundColor: '#d9534f', 
              marginLeft: '20px',
              borderColor: '#d9534f', 
              padding: '5px 10px', 
              width: '100px', 
              fontSize: '14px' 
            }} 
            className="btn btn-danger"
            onClick={handleClearCart}
          >
            Clear Cart
          </Button>
        </div>
        {cart.items.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          cart.items.map(item => (
            <CartItem
              key={item.drug._id}
              item={item}
              onUpdateQuantity={handleUpdateQuantity}
              onRemove={handleRemoveItem}
            />
          ))
        )}
      </div>
    </Layout>
  );
};

export default Cart;
