import React, { useEffect } from "react"
import Modal from "react-modal"
import logo from "../../images/patamedslogo.svg"
import styled from "styled-components"
import { Link } from "gatsby"

const customStyles = {
  content: {
    top: "25%",
    bottom: "auto",
  },
  overlay: {
    zIndex: "10000",
  },
}

export default function InfoModal() {
  const isBrowser = typeof window !== "undefined"
  const isLoggedIn = isBrowser ? !!window.localStorage.getItem("user") : {}

  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  useEffect(() => {
    setTimeout(() => {
      if (!isLoggedIn) {
        if (typeof window !== "undefined") {
          openModal()
        }
      }
    }, 12000)

  }, [])

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
      <Container>
        <HeroText>
          <LinkLogo>
            <img src={logo} alt="logo" />
          </LinkLogo>
          <p>
            This site is intended for Healthcare Professionals Only
          </p>
        </HeroText>
        <Button css={`border:3px solid white`} to="/login" onClick={closeModal}>
          Healthcare Provider
        </Button>
        <Button css={`border:3px solid white`} to="https://www.afyabook.com/pharmacy" onClick={closeModal}>
          Consumer or Patient
        </Button>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    #1c333a 10%,
    #1b353c 50%,
    #03989e 70%,
    #03989e 100%
  );
  //height: 50vh;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem;
  z-index: 4000;
  margin: -1.1rem -1.1rem;
`
const HeroText = styled.div`
  color: #fff;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
  font-size: clamp(1.25rem, 3vw, 1.5rem);
`
const LinkLogo = styled.div`
  width: 60%;
  margin: auto;
  margin-bottom: 2rem;

  @media screen and (min-width: 600px) {
    width: 40%;
  }
  @media screen and (min-width: 800px) {
    width: 30%;
  }
  @media screen and (min-width: 1100px) {
    width: 20%;
  }
  @media screen and (min-width: 1500px) {
    width: 10%;
  }
`
const Button = styled(Link)`
  background: #00a14b;
  padding: 0.1rem 0.5rem;
  border-radius: 3rem;
  color: #fff;
  margin-bottom: 1rem;

  @media screen and (min-width: 600px) {
    padding: 1rem 5rem;
  }
`
