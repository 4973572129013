import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const CartItem = ({ item, onUpdateQuantity, onRemove }) => {
  const [quantity, setQuantity] = useState(item.quantity);
  const [distributor, setDistributor] = useState(null);
  const [wholesalers, setWholesalers] = useState([]);
  const [loadingDistributor, setLoadingDistributor] = useState(true);
  const [loadingWholesalers, setLoadingWholesalers] = useState(true);
  const [error, setError] = useState(null);

  const distributorId = item.drug.distributor.distributor;
  const wholesalerIds = useMemo(() => item.drug.wholesalers.map(wh => wh.wholesaler).filter(Boolean), [item.drug.wholesalers]);

  useEffect(() => {
    async function fetchDistributor() {
      setLoadingDistributor(true);
      try {
        const response = await fetch(`${process.env.GATSBY_API_URL}/distributors/${distributorId}`);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.json();
        setDistributor(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoadingDistributor(false);
      }
    }

    if (distributorId) {
      fetchDistributor();
    } else {
      setLoadingDistributor(false);
    }
  }, [distributorId]);

  useEffect(() => {
    let isMounted = true;

    async function fetchWholesalers() {
      setLoadingWholesalers(true);
      if (wholesalerIds.length > 0) {
        try {
          const response = await fetch(`${process.env.GATSBY_API_URL}/wholesalers/batch`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ids: wholesalerIds })
          });
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
          }
          const data = await response.json();
          if (isMounted) {
            setWholesalers(data);
          }
        } catch (error) {
          if (isMounted) {
            setError(error.message);
          }
        } finally {
          if (isMounted) {
            setLoadingWholesalers(false);
          }
        }
      } else {
        setLoadingWholesalers(false);
      }
    }

    fetchWholesalers();

    return () => {
      isMounted = false;
    };
  }, [wholesalerIds]);

  const handleQuantityChange = (e) => {
    const newQuantity = Number(e.target.value);
    if (newQuantity >= 0) {
      setQuantity(newQuantity);
      onUpdateQuantity(item.drug._id, newQuantity, item.drug.selectedSeller, item.drug.sellerType, item.drug.price);
    }
  };

  const handleButtonClick = (change) => {
    const newQuantity = Math.max(0, quantity + change);
    setQuantity(newQuantity);
    onUpdateQuantity(item.drug._id, newQuantity, item.drug.selectedSeller, item.drug.sellerType, item.drug.price);
  };

  return (
    <CartItemContainer>
      <ProductName>{item.drug.productTradeName}</ProductName>
      {/* 
        <ProductLabel>Distributor:</ProductLabel>
        {loadingDistributor ? (
          <p>Loading distributor...</p>
        ) : error ? (
          <p>Error loading distributor</p>
        ) : (
          <WholesalerItem>{distributor ? distributor.name : `No distributor found (ID: ${distributorId})`}</WholesalerItem>
        )}
        <ProductLabel>Wholesalers:</ProductLabel>
        {loadingWholesalers ? (
          <p>Loading wholesalers...</p>
        ) : error ? (
          <p>Error loading wholesalers</p>
        ) : (
          wholesalers.length > 0 ? (
            wholesalers.map(wholesaler => (
              <WholesalerItem key={wholesaler._id}>
                {wholesaler.name}
              </WholesalerItem>
            ))
          ) : (
            `No wholesalers found (IDs: ${wholesalerIds.join(', ')})`
          )
        )} 
      */}
      <QuantityAndButtons>
        <Button 
          style={{ backgroundColor: '#03989e', borderColor: '#03989e', width: '60px', padding: '5px 10px', fontSize: '14px', marginBottom: '10px' }} 
          onClick={() => handleButtonClick(-1)}
        >
          -
        </Button>
        <QuantityInput 
          type="number" 
          value={quantity} 
          onChange={handleQuantityChange}
          min="0"
        />
        <Button 
          style={{ backgroundColor: '#03989e', borderColor: '#03989e', width: '60px', padding: '5px 10px', fontSize: '14px', marginBottom: '10px' }} 
          onClick={() => handleButtonClick(1)}
        >
          +
        </Button>
      </QuantityAndButtons>
      <RemoveButton 
        style={{ backgroundColor: '#03989e', borderColor: '#03989e', marginBottom: '10px'}} 
        onClick={() => onRemove(item.drug._id)}
      >
        Remove
      </RemoveButton>
    </CartItemContainer>
  );
};

export default CartItem;

const CartItemContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
`;

const ProductName = styled.h3`
  margin: 0;
  flex: 1 1 100%;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
`;

const QuantityAndButtons = styled.div`
  display: flex;
  align-items: center;
`;

const QuantityInput = styled.input`
  width: 60px; 
  margin: 0 10px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const RemoveButton = styled(Button)`
  padding: 5px 10px;
  width: 100px;
  border-radius: 20px;
`;

const WholesalerItem = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: 16px;
  font-weight: 500;
  width: 300px;
`;

const ProductLabel = styled.h4`
  margin: 0;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  background-color: #e0f7fa;
  border-radius: 4px;
  border: 1px solid #b2ebf2;
  display: inline-block;
`;
